import React from 'react';

class AppServices extends React.Component {
    render() {
        return (
            <div className="container content-lg">
                <div className="title-v1">
                    <h2>Nos Services</h2>
                    <p>Nous agissons pour des systèmes d’information plus <strong>simple</strong> plus
                        évolutifs, plus sécurisés
                        et plus économiques.
                        <br/>
                        Ce qui nous amène à suivre la tendance des <strong className="color-green">nouvelles
                            technologies</strong>
                    </p>
                </div>

                <div className="row service-box-v1">
                    <div className="col-md-4 col-sm-6">
                        <div className="service-block service-block-default">
                            <i className="icon-custom icon-lg icon-bg-u rounded-x fa fa-lightbulb-o"/>
                            <h2 className="heading-md">Intégration</h2>
                            <p>Les meilleurs solutions du marché pour vos besoins</p>
                            <ul className="list-unstyled">
                                <li>Salesforce</li>
                                <li>Web services</li>
                                <li>Carte bancaire</li>
                                <li>Mobile Payment</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service-block service-block-default">
                            <i className="icon-custom icon-lg icon-bg-u rounded-x icon-line icon-present"/>
                            <h2 className="heading-sm">Développement</h2>
                            <p>Nous transformons vos idées en produits innovants sur mésure</p>
                            <ul className="list-unstyled">
                                <li>Apps Mobile (Android & IOS)</li>
                                <li>Logiciel</li>
                                <li>E-Commerce</li>
                                <li>Application web</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="service-block service-block-default">
                            <i className="icon-custom icon-lg icon-bg-u rounded-x icon-line icon-rocket"/>
                            <h2 className="heading-sm">Conseil</h2>
                            <p>Nous vous accompagnons dans vos projets de transformation digitale</p>
                            <ul className="list-unstyled">
                                <li>Transformation Digitale</li>
                                <li>Formation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppServices;
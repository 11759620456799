import React from 'react';
import {sendAlert} from './AppAlert';

class AppContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            submitted: false,
            submitting: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitted: false,
            submitting: true
        });
        let form = event.target;
        let payload = {
            "channel": "#feedback",
            "username": "contact",
            "text": "Nouveau Feedback",
            "attachments": [
                {
                    "fallback": "Message de : " + this.state.name,
                    "color": "#00D000",
                    "title": `${this.state.subject || this.state.name}`,
                    "title_link": `mailto:${this.state.email}`,
                    "fields": [
                        {
                            "title": 'Informations',
                            "value": `${this.state.name}\n${this.state.email}\n${this.state.message}`,
                            "short": false
                        }
                    ]
                }
            ]
        };
        let body = JSON.stringify(payload);
        fetch('/api/slack/message', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
            body: body
        })
            .then(res => res.json())
            .then(response => {
                console.log('response', response);
                if (response.code / 100 !== 2) {
                    this.handleError(response);
                } else {
                    this.setState({
                        name: '',
                        email: '',
                        message: '',
                        submitted: true,
                        submitting: false
                    });
                    form.reset();
                    sendAlert({
                        type: 'alert-event',
                        alert: 'success',
                        title: 'Contact',
                        message: "Merci pour votre message"
                    });
                }
            })
            .catch(this.handleError);
    }

    handleChange(event) {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleError(event) {
        this.setState({
            submitting: false
        });
        sendAlert({
            type: 'alert-event',
            alert: 'danger',
            title: 'Contact',
            message: event.message
        });
    }

    render() {
        return (
            <div className="container content-lg">
                <div className="title-v1">
                    <h2>Contactez nous</h2>
                    <p>Vous pouvez nous contacter via le formulaire ci-dessous, nous sommes assez réactifs.</p>
                </div>

                <div className="row contacts-in">
                    <div className="col-md-6 md-margin-bottom-40">
                        <ul className="list-unstyled">
                            <li><i className="fa fa-home"/> Villeuve-la-Garenne, France</li>
                            <li><i className="fa fa-phone"/> +33(0)620790921</li>
                            <li><i className="fa fa-envelope"/> <a
                                href="mailto:contact@djammadev.com">contact@djammadev.com</a>
                            </li>
                            <li><i className="fa fa-globe"/> <a
                                href="https://github.com/mcicheick">Github</a></li>
                            <li><i className={"fa fa-facebook"}/> <a
                                href="https://www.facebook.com/djammadev" target={"_blank-fb"}>Facebook</a></li>
                            <li><i className={"fa fa-twitter"}/> <a
                                href="https://www.twitter.com/djammadev" target={"_blank-tw"}>Twitter</a></li>
                            <li><i className={"fa fa-linkedin"}/> <a
                                href="https://www.linkedin.com/company/djamma-dev" target={"_blank-in"}>Linked In</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-6">
                        <form id="feedback"
                              method="post"
                              className="sky-form contact-style" onSubmit={this.handleSubmit}>
                            <fieldset>
                                <label>Name <span className="color-red">*</span></label>
                                <div className="row">
                                    <div className="col-md-7 margin-bottom-20 col-md-offset-0">
                                        <div>
                                            <input type="text" name="name" id="name" required={true}
                                                   className="form-control" value={this.state.name}
                                                   onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                </div>

                                <label>Email <span className="color-red">*</span></label>
                                <div className="row">
                                    <div className="col-md-7 margin-bottom-20 col-md-offset-0">
                                        <div>
                                            <input type="text" name="email" id="email" required={true}
                                                   className="form-control" value={this.state.email}
                                                   onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                </div>

                                <label>Message <span className="color-red">*</span></label>
                                <div className="row">
                                    <div className="col-md-11 margin-bottom-20 col-md-offset-0">
                                        <div>
                                            <textarea rows="8" name="message" id="message" required={true}
                                                      className="form-control" value={this.state.message}
                                                      onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                </div>

                                <p>
                                    <button type="submit"
                                            className={'btn-u btn-brd btn-brd-hover btn-u-dark' + (this.state.submitting ? ' spin' : '')}>Send
                                        Message <span className="spinner"/>
                                    </button>
                                </p>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppContact;
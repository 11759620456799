import React from 'react';
import './AppIntroduction.css'

class AppIntroduction extends React.Component {
    render() {
        return (
            <div className="fullscreenbanner-container">
                <div className="fullscreenbanner">
                    <ul>
                        <li data-transition="curtain-1" data-slotamount="5" data-masterspeed="700" data-title="Slide 1">
                            <img src="assets/img/sliders/revolution/bg1.jpg" alt="slidebg1" data-bgfit="cover"
                                 data-bgposition="center center" data-bgrepeat="no-repeat"/>

                            <div className="tp-caption rs-caption-1 sft start z-index-6"
                                 data-x="center"
                                 data-hoffset="0"
                                 data-y="100"
                                 data-speed="800"
                                 data-start="2000"
                                 data-easing="Back.easeInOut"
                                 data-endspeed="300">
                                NOUS SOMMES DJAMMA DEV
                            </div>

                            <div className="tp-caption rs-caption-2 sft z-index-6"
                                 data-x="center"
                                 data-hoffset="0"
                                 data-y="200"
                                 data-speed="1000"
                                 data-start="3000"
                                 data-easing="Power4.easeOut"
                                 data-endspeed="300"
                                 data-endeasing="Power1.easeIn"
                                 data-captionhidden="off">
                                Développement des solutions web et mobile
                            </div>

                            <div className="tp-caption rs-caption-3 sft z-index-6"
                                 data-x="center"
                                 data-hoffset="0"
                                 data-y="360"
                                 data-speed="800"
                                 data-start="3500"
                                 data-easing="Power4.easeOut"
                                 data-endspeed="300"
                                 data-endeasing="Power1.easeIn"
                                 data-captionhidden="off">
                                    <span className="page-scroll"><a href="#about"
                                                                     className="btn-u btn-brd btn-brd-hover btn-u-light">En Savoir plus</a></span>
                                <span className="page-scroll"><a href="#portfolio"
                                                                 className="btn-u btn-brd btn-brd-hover btn-u-light">Nos réalisations</a></span>
                            </div>
                        </li>
                    </ul>
                    <div className="tp-bannertimer tp-bottom"/>
                    <div className="tp-dottedoverlay twoxtwo"/>
                </div>
            </div>
        );
    }
}

export default AppIntroduction;
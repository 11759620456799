import React from 'react';
import './AppContainer.css'
import AppHeader from "./AppHeader";
import AppIntroduction from "./AppIntroduction";
import AppAbout from "./AppAbout";
import AppServices from "./AppServices";
import AppPortfolio from "./AppPortfolio";
// import AppApply from "./AppApply";
import AppContact from "./AppContact";
import AppAlert from "./AppAlert";

const {$, App, LoginForm, ContactForm, OwlCarousel, StyleSwitcher, RevolutionSlider} = window;

class AppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    title: "Chiesulu",
                    html: "assets/ajax/chiesulu.html",
                    image: "assets/img/portfolio/chiesulu.png",
                    link: "https://chiesulu.djammadev.com",
                    description: "Chiesulu : gestion d'aviculture."
                },
                {
                    title: "Dogojo",
                    html: "assets/ajax/dogojo.html",
                    image: "assets/img/portfolio/dogojo.jpeg",
                    link: "https://dogojo.cpd-mali.com",
                    description: "Place de marché pour initiatives des membres de la CPD"
                },
                {
                    title: "Barinta",
                    html: "assets/ajax/barinta.html",
                    image: "assets/img/portfolio/barinta.png",
                    link: "https://www.barinta.org",
                    description: "Association Barinta : Aide des enfants défavorisés"
                },
                {
                    title: "CPD",
                    html: "assets/ajax/cpd.html",
                    image: "assets/img/portfolio/cpd.jpg",
                    link: "https://www.cpd-mali.com",
                    description: "Cellule Pour le Développement"
                },
                {
                    title: "Wemblee",
                    html: "assets/ajax/wemblee.html",
                    image: "assets/img/portfolio/wemblee.png",
                    link: "#",
                    description: "Wemblee : GESTION IMMOBILIÈRES"
                },
                {
                    title: "Surofinkele",
                    html: "assets/ajax/surofinkele.html",
                    image: "assets/img/portfolio/surofinkele.jpg",
                    link: "https://surofinkele.herokuapp.com",
                    description: "La fin de la corruption au Mali"
                },
                {
                    title: "C&M",
                    html: "assets/ajax/candm.html",
                    image: "assets/img/portfolio/candm.png",
                    link: "https://candm.djammadev.com",
                    description: "C&M : Application de gestion de compte"
                },
                {
                    title: "Examinfo",
                    html: "assets/ajax/examinfo.html",
                    image: "assets/img/portfolio/examinfo.jpg",
                    link: "https://exameninfo.herokuapp.com",
                    description: "Résultat des examens au Mali"
                },
                {
                    title: "Djamma Bot",
                    html: "assets/ajax/djamma-bot.html",
                    image: "assets/img/portfolio/djamma-bot.png",
                    link: "https://ussd.djammadev.com",
                    description: "Service de Bot pour Facebook message et Orange Mali USSD Service."
                },
                /*{
                    title: "COVID-19",
                    html: "assets/ajax/covid19.html",
                    image: "https://via.placeholder.com/400x400?text=COVID-19",
                    link: "https://covid19.cpd-mali.com",
                    description: "Plateforme pour mettre à la disposition des bénévoles aux services hospitaliers du Mali."
                },*/
            ]
        };
    }

    render() {
        return (
            <>
                <AppHeader/>
                <AppAlert/>
                <section id="intro" className="intro-section">
                    <AppIntroduction/>
                </section>
                <section id="about" className="about-section section-first">
                    <AppAbout/>
                </section>
                <section id="services">
                    <AppServices/>
                </section>
                <section id="portfolio">
                    <AppPortfolio projects={this.state.projects}/>
                </section>
                {/*<section id="apply" className="apply-section">
                    <AppApply/>
                </section>*/}
                <section id="contact" className="contacts-section">
                    <AppContact/>
                </section>
                <section id="copyright">
                    <div className="copyright-section">
                        <p>{new Date().getFullYear()} &copy; All Rights Reserved. <span
                            className="djamma-dev"><span>Djamma</span> Dev</span></p>
                        <ul className="social-icons">
                            <li>
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://www.facebook.com/Djamma-Dev-281565102794793/"
                                   data-original-title="Facebook" className="social_facebook rounded-x"><i/></a>
                            </li>
                            <li><a target="_blank" rel="noopener noreferrer"
                                   href="https://twitter.com/Mcicheick"
                                   data-original-title="Twitter"
                                   className="social_twitter rounded-x"><i/></a>
                            </li>
                            <li>
                                <a href="/#" rel="noopener noreferrer" data-original-title="Goole Plus"
                                   className="social_googleplus rounded-x"><i/></a>
                            </li>
                            <li>
                                <a href="/#" rel="noopener noreferrer" data-original-title="Pinterest"
                                   className="social_pintrest rounded-x"><i/></a>
                            </li>
                            <li>
                                <a href="/#" rel="noopener noreferrer" data-original-title="Linkedin"
                                   className="social_linkedin rounded-x"><i/></a>
                            </li>
                        </ul>
                        <span className="page-scroll">
                            <a href="#intro"><i
                                className="fa fa-angle-double-up back-to-top"/></a>
                        </span>
                    </div>
                </section>
            </>
        );
    }

    componentDidMount() {
        $(document).ready(function () {
            App.init();
            App.initCounter();
            App.initParallaxBg();
            LoginForm.initLoginForm();
            ContactForm.initContactForm();
            OwlCarousel.initOwlCarousel();
            StyleSwitcher.initStyleSwitcher();
            RevolutionSlider.initRSfullScreen();
        });
    }
}

export default AppContainer;
import React from 'react';

const $ = window.$;
const initCubeportfolio = window.initCubeportfolio;

class AppPortfolio extends React.Component {
    render() {
        return (
            <>
                <div className="container content-lg">
                    <div className="title-v1">
                        <h2>Nos réalisations</h2>
                        <p>Nous faisons des <strong>choses</strong> différemment en fournissant des services
                            numériques clés. <br/>
                            Axé sur l'aide à nos clients pour créer une solution <strong>prospère</strong> sur
                            le web et le mobile.
                        </p>
                    </div>

                    <div className="cube-portfolio">
                        <div id="grid-container" className="cbp-l-grid-gallery">
                            {this.props.projects.map((item, index) => {
                                return (<div key={index} className="cbp-item print motion">
                                    <a href={item.html} className="cbp-caption cbp-singlePageInline"
                                       data-title={item.title + '<br/>by Djamma Dev'}>
                                        <div className="cbp-caption-defaultWrap">
                                            <img src={item.image} alt={item.title}/>
                                        </div>
                                        <div className="cbp-caption-activeWrap">
                                            <div className="cbp-l-caption-alignLeft">
                                                <div className="cbp-l-caption-body">
                                                    <div className="cbp-l-caption-title">{item.title}</div>
                                                    <div className="cbp-l-caption-desc">{item.description}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>

                <div className="clients-section parallaxBg">
                    <div className="container">
                        <div className="title-v1">
                            <h2>Nos Clients</h2>
                        </div>
                        <ul className="owl-clients-v2">
                            {this.props.projects.map((item, index) => {
                                return (
                                    <li key={index} className="item">
                                        <a target="_blank" rel="noopener noreferrer"
                                           href={item.link}>
                                            <img src={item.image} alt={item.title}/>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        initCubeportfolio($, '#grid-container', '#filters-container');
    }
}

export default AppPortfolio;
import React from 'react';

class AppHeader extends React.Component {

    render() {
        return (
            <nav className="one-page-header navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container">
                    <div className="menu-container page-scroll">
                        <button type="button" className="navbar-toggle" data-toggle="collapse"
                                data-target=".navbar-ex1-collapse">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>

                        <a className="navbar-brand" href="#intro">
                            <span>Djamma</span>Dev
                        </a>
                    </div>

                    <div className="collapse navbar-collapse navbar-ex1-collapse">
                        <div className="menu-container">
                            <ul className="nav navbar-nav">
                                <li className="page-scroll">
                                    <a href="#about">A propos de nous</a>
                                </li>
                                <li className="page-scroll">
                                    <a href="#services">Nos Services</a>
                                </li>
                                <li className="page-scroll">
                                    <a href="#portfolio">Nos réalisations</a>
                                </li>
                                {/*<li className="page-scroll">
                                    <a href="#apply">Offre d'emploi</a>
                                </li>*/}
                                <li className="page-scroll">
                                    <a href="#contact">Nous contacter</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default AppHeader;
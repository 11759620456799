import React from 'react';

class AppAbout extends React.Component {
    render() {
        return (
            <>
                <div className="container content-lg">
                    <div className="title-v1">
                        <h2>NOTRE VISION, NOTRE MISSION</h2>
                        <p>Nous chercherons à <strong>comprendre</strong> votre vision et vos besoins. <br/>
                            Pour vous construire des solutions qui vous permettront d'atteindre
                            le <strong>succès</strong>.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 content-boxes-v3 margin-bottom-40">
                            <div className="clearfix margin-bottom-30">
                                <i className="icon-custom icon-md rounded-x icon-bg-u icon-line icon-trophy"/>
                                <div className="content-boxes-in-v3">
                                    <h2 className="heading-sm">LEADER EN INNOVATION</h2>
                                    <p>Notre priorité est votre succès. Nous innovons sans cesse afin de vous
                                        proposer une approche
                                        originale.</p>
                                </div>
                            </div>
                            <div className="clearfix margin-bottom-30">
                                <i className="icon-custom icon-md rounded-x icon-bg-u icon-line icon-directions"/>
                                <div className="content-boxes-in-v3">
                                    <h2 className="heading-sm">L'APPROCHE DE LA MEILLEURE SOLUTION POSSIBLE</h2>
                                    <p>Nous vous écoutons, étudions votre projet afin de vous donner la meilleure
                                        stratégie adaptée
                                        à votre objectif.</p>
                                </div>
                            </div>
                            <div className="clearfix margin-bottom-30">
                                <i className="icon-custom icon-md rounded-x icon-bg-u icon-line icon-diamond"/>
                                <div className="content-boxes-in-v3">
                                    <h2 className="heading-sm">SUPPORT &amp; QUALITÉ DE SERVICE</h2>
                                    <p>Dans le suivi de notre démarche qualité, nous vous accompagnerons avant,
                                        pendant et après vos
                                        projets.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <img className="img-responsive" src="assets/img/mockup/approach.jpg" alt="Approach"/>
                        </div>
                    </div>
                </div>

                <div className="parallax-quote parallaxBg">
                    <div className="container">
                        <div className="parallax-quote-in">
                            <p>La <span className="color-green">satisfaction</span> du client est notre
                                priorité. <br/> Nous vous <span
                                    className="color-green">écoutons</span> et nous réalisons la <span
                                    className="color-green">solution adaptée</span>
                                à votre besoin.</p>
                            <small>- Djamma Dev -</small>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AppAbout;
